<template>
    <v-row
        class="mt-2"
    >            
        <v-col class="py-0 flex-grow-0">
            <v-card                    
                width="500"
                class=" ml-3"
                flat                    
            >
                <v-form ref="form">
                    <v-textarea                                       
                        label="Form Teacher's Remarks"                            
                        persistent-hint
                        dense
                        rows="2"
                        :value="value"                        
                        counter                                            
                        class="text-caption"
                        :rules="rules"                      
                        no-resize
                        maxlength="215"
                        v-model="studentRecord.comments"                            
                        @blur="saveTeacherComment"
                        @click="openBottomSheet('comments')"
                        :readonly="disabled"
                        :filled="disabled"
                    >                        
                    </v-textarea>
                </v-form>
            </v-card>
        </v-col>
        <v-col class="pa-0 flex-grow-0">
            <v-card                    
                width="70"
                flat
                outlined
                class="ml-3"
            >
                <v-card-title
                    class="pb-0 px-0 d-flex justify-center"
                    style="font-size:0.7rem"
                >
                    Average
                </v-card-title>
                <v-card-text
                    class="text-center text-h6 font-weight-bold px-0 primary--text"                        
                >
                    <span :style="styleFontSize">{{ studentAverage }}</span>
                </v-card-text>
            </v-card>
        </v-col>

        <!-- <v-col class="pa-0 flex-grow-0">
            <v-card                    
                width="70"
                flat
                outlined
                class="ml-3"
            >
                <v-card-title
                    class="pb-0 d-flex justify-center"
                    style="font-size:0.7rem"
                >
                    Grade
                </v-card-title>
                <v-card-text
                    class="text-center text-h6 font-weight-bold px-0 primary--text"                        
                >
                    <span style="font-family: Times">{{ studentGrade }}</span>
                </v-card-text>
            </v-card>
        </v-col> -->

        <v-col class="pa-0 flex-grow-0">
            <v-card
                flat
                width="282"
            >
                <v-row
                    style="position:absolute; top:-25px; right:15px" 
                    class="pl-3 ml-0 mb-1"
                >
                    
                    <v-card 
                        width="100" 
                        flat
                        class="ml-auto mr-2"
                        style="font-size:0.55rem; text-align: center"
                    >
                        All Classes
                    </v-card>
                    <v-card 
                        width="80" 
                        flat 
                        style="font-size:0.55rem; text-align: center"                           
                    >
                        Current Class
                    </v-card>                       
                </v-row>

                <v-row class="pl-3 mx-0 mb-2">
                    <v-card 
                        flat 
                        style="font-size:0.7rem"
                        class="mr-2"
                        width="82"
                    >
                        Possible Att.
                    </v-card>
                    <v-card 
                        width="86" 
                        flat
                        class="mr-2"
                    >
                        <v-text-field
                            v-model="possibleAttendanceAll"                                
                            outlined
                            hide-details
                            dense
                            style="font-size:0.7rem"
                            type="number"
                            @blur="changePossibleAttendance('All')"
                            :disabled="!admin"
                        ></v-text-field>
                    </v-card>
                    <v-card 
                        width="86" 
                        flat                            
                    >
                        <v-text-field
                            v-model="studentRecord.possible_attendance"
                            outlined
                            hide-details
                            dense
                            style="font-size:0.7rem"
                            type="number"
                            @blur="changePossibleAttendance(studentRecord.class_id)"
                            :disabled="!admin"
                        ></v-text-field>
                    </v-card>                       
                </v-row>

                <v-row class="pl-3 ml-0 mb-2">
                    <v-card 
                        flat 
                        style="font-size:0.7rem"
                        class="mr-2"
                        width="82"
                    >
                        School Reopens
                    </v-card>
                    <v-card 
                        width="86" 
                        flat
                        class="mr-2"
                    >                            
                        <date-picker 
                            v-on:change-date="newTermDate($event,'All')"
                            v-bind:date="newTermBeginningAll"
                            :disabled="!admin"                                
                        ></date-picker>                           
                    </v-card>
                    <v-card 
                        width="86"
                        style="font-size:0.7rem" 
                        flat                            
                    >
                        <date-picker 
                            v-on:change-date="newTermDate($event, studentRecord.class_id)"
                            v-bind:date="studentRecord.new_term_beginning"
                            :disabled="!admin"
                        ></date-picker> 
                    </v-card>                       
                </v-row>

                <v-row class="pl-3 ml-0">
                    <v-card 
                        flat 
                        style="font-size:0.7rem"
                        class="mr-2"
                        width="82"
                    >
                        Change Class
                    </v-card>
                    <v-card 
                        width="86" 
                        flat
                        class="mr-2"
                    >
                        <v-select
                            outlined
                            hide-details
                            dense
                            :items="classOptions"
                            class="change-class"
                            style="font-size:0.6rem"
                            v-model="studentRecord.class_id"
                            @change="changeClass"
                            :disabled="!admin"
                        ></v-select>
                    </v-card>
                    <v-card 
                        width="86" 
                        flat                            
                    >
                        <v-btn                                
                            color="primary"
                            x-small
                            height="30"
                            block
                            @click="deleteRecord"
                            :disabled="!admin"                                
                        >
                            Delete
                        </v-btn>
                    </v-card>                       
                </v-row>

            </v-card>
        </v-col>         
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DatePicker from './DatePicker.vue';
export default {
    components: {        
        DatePicker,
    },

    data: function () {
        return {
            value: ' ', 
            rules: [],
            disabled: true,
            styleFontSize: null,
            studentAverage: '',
            possibleAttendanceAll: null,
            newTermBeginningAll: '',
            classOptions: [],
        }
    },

    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getSelectedStudentRecord',
            admin: 'auth/getAdmin',
            table2Records: 'termReports/getEditViewTermReportsTable2Records',
            formClasses: 'termReports/getFormClasses',
            formTeacherClass: 'termReports/getFormTeacherClass',
        })
    },

    watch: {
        table2Records: {
            deep: true,
            handler () {                                           
                this.getAverage();
                this.setDisabledFields();
            }
        },

        formClasses: {
            handler () {
                this.setFormClasses();
            }
        }
    },

    methods: {

        initialize () {            
            this.getAverage();
        },

        ...mapActions({
            postTable1Record: 'termReports/postTable1Record',
            getTable1Record: 'termReports/getTable1Record',
            postChangeClass: 'admin/changeClass',
            postPossibleAttendance: 'admin/postPossibleAttendance',
            postNewTermBeginning: 'admin/postNewTermBeginning',
        }),

        ...mapMutations({
            setBottomSheetModel: 'termReports/setBottomSheetModel',
            setBottomSheetTitle: 'termReports/setBottomSheetTitle',
            overlay: 'termReports/setEditViewTermReportsOverlay',
            loading: 'termReports/setEditViewTermReportsLoading',
            setPossibleAttendance: 'admin/setPossibleAttendance',
            setNewTermBeginning: 'admin/setNewTermBeginning',
            setSnackbar: 'admin/setSnackbar',
            setSelectedTable1Record: 'admin/setSelectedTable1Record',
            setDialogDelete: 'admin/setDialogDelete',
            setChangeClassRecord: 'admin/setChangeClassRecord',
            setCommentSheet: 'termReports/setCommentSheet',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
        }),

        saveTeacherComment ( ){            
            this.updateRecord();
        },

        async updateRecord () {
            console.log('updating...');
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            //this.studentRecord.lib_comment = this.codedComment.id;           
            //console.log(this.studentRecord);
            try{
                await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                //console.log(response);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }            
        },

        openBottomSheet (model) {
            this.setBottomSheetModel(model); 
            if(model == 'comments'){
                this.setBottomSheetTitle('Form Teacher Comments');
            }
            else{
                this.setBottomSheetTitle('General Remarks: Conduct');
            }          
            if(!this.disabled) this.setCommentSheet(true);
            //console.log(`sheet: ${ this.sheet }`);
        },

        studentGrade () {                    
            if(this.studentAverage){
                 if(this.formLevel < 6){
                    if(this.studentAverage >= 80 ) return 'A';
                    else if(this.studentAverage >= 65) return 'B';
                    else if(this.studentAverage >= 50) return 'C';                    
                    else if(this.studentAverage >=0 ) return 'F';
                 }
                 else{
                    if(this.studentAverage >= 80) return 'I';
                    else if(this.studentAverage >= 70) return 'II';
                    else if(this.studentAverage >= 60) return 'III';
                    else if(this.studentAverage >= 50) return 'IV';
                    else if(this.studentAverage >= 40) return 'V';
                    else if(this.studentAverage >= 30) return 'VI';
                    else if(this.studentAverage >= 0) return 'U';
                 }                
            }           
            
            return '-';
        },

        async changeClass () {
            this.overlay(true);
            this.loading(true);            
            this.setChangeClassRecord({
                student_id: this.studentRecord.student_id,
                class_id: this.studentRecord.class_id
            })
            try {
                let response = await this.postChangeClass();
                this.setSnackbar({
                    text: `Class changed to ${this.studentRecord.class_id}`,
                    display: true,
                    color: 'primary',
                });               
                console.log(response);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSnackbar({
                    text: `Error occured`,
                    display: true,
                    color: 'red',
                });              
            }            
            this.overlay(false);
            this.loading(false);
        },

        deleteRecord () {
            // console.log(this.table2Records);
            this.setSelectedTable1Record(this.studentRecord);
            if(this.table2Records.length != 0){
                this.setDialogDelete({
                    display: true,
                    text: 'Cannot delete record with existing subject records. Please delete all subject records first.',
                    cancel: false,
                    deleteSubject: false,
                    deleteStudent: false,
                    ok: true
                })
            }
            else{
                console.log(this.studentRecord);
                this.setDialogDelete({
                    display: true,
                    text: `Delete ${this.studentRecord.first_name} ${this.studentRecord.last_name} ?`,
                    cancel: true,
                    deleteSubject: false,
                    deleteStudent: true,
                    ok: false
                })
            }
        },

        getAverage(){            
            this.styleFontSize = null;                   
            // let recordCount = 0, totalMarks = 0; 
            this.studentAverage = '--';
                    
            // this.table2Records.forEach(record => {
            //     recordCount++;
            //     totalMarks += record.exam_mark;                
            // })            
            
            // if(recordCount != 0 ){               
            //     this.studentAverage =  (totalMarks / recordCount).toPrecision(3);
            // }
            // else if(recordCount == 0) this.studentAverage = '--';
        },

        async changePossibleAttendance (formClass) {
            console.log(formClass);            
            this.overlay(true);
            this.loading(true);

            let snackbar = {};

            if(formClass == 'All'){
                this.setPossibleAttendance({
                    possible_attendance: this.possibleAttendanceAll,
                    class_id: null,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `Possible attendance set to ${this.possibleAttendanceAll} for all classes.`
            }
            else{
                this.setPossibleAttendance({
                    possible_attendance: this.studentRecord.possible_attendance,
                    class_id: this.studentRecord.class_id,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                })
                snackbar.text = `Possible attendance set to ${this.studentRecord.possible_attendance} for ${this.studentRecord.class_id}.`
            }

            try {
                let response = await this.postPossibleAttendance();
                if(formClass == 'All') 
                this.studentRecord.possible_attendance = this.possibleAttendanceAll;
                snackbar.color = 'primary';
                snackbar.display = true;
                this.setSnackbar(snackbar);  
                console.log(response.status);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                snackbar.color= 'red';
                snackbar.display = true;
                snackbar.text = 'An Error Has Occured';
                this.setSnackbar(snackbar);
            }
            
            this.overlay(false);
            this.loading(false);
        },

        async newTermDate (e, formClass) {
            this.overlay(true);
            this.loading(true);
            this.studentRecord.new_term_beginning = e;

            let snackbar = {};

            if(formClass == 'All'){
                this.setNewTermBeginning({
                    new_term_beginning: e,
                    class_id: null,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `New Term Beginning set to ${e} for all classes.`
            }
            else{
                this.setNewTermBeginning({
                    new_term_beginning: e,
                    class_id: this.studentRecord.class_id,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `New Term Beginning set to ${e} for ${formClass}.`
            }
            // console.log(`Date: ${e}, class: ${formClass}`);

            try {
                let response = await this.postNewTermBeginning();
                console.log(response);
                snackbar.color = 'primary';
                snackbar.display = true;
                this.setSnackbar(snackbar);  
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                snackbar.color= 'red';
                snackbar.display = true;
                snackbar.text = 'An Error Has Occured';
            }

            this.overlay(false);
            this.loading(false);
            
        },

        setFormClasses () {
            this.formClasses.forEach(value => {
                value.items.forEach(item => {
                    this.classOptions.push(item.title);
                })
            });
        },

        setDisabledFields () {            
            this.disabled = false;
            if(
                this.formTeacherClass != this.studentRecord.class_id
                && !this.admin
            ){                    
                    this.disabled = true;
            }                
        },
    }
}
</script>

<style scoped>
    ::v-deep .change-class .v-input__icon{
        min-width: 10px;
        width: 10px;
    }

    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    ::v-deep .v-select__selections{
        height: 30px;
        /*padding: 0 !important;*/
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    ::v-deep .v-input input{
        height: 25px;
    }
</style>