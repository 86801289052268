<template>
    <v-col class="pr-0 pb-0 mb-0">
        <v-row class="mx-0" >
            <v-col align-self="center" class="py-0">
                <v-card
                    flat
                    
                    align="center"
                    justify="center"
                    
                    light
                    tile
                >
                    <span 
                        class="mr-3 text-subtitle-1 primary--text font-weight-bold"                                         
                    > {{ studentName }}</span>                                 
                    <span 
                        class="text-subtitle-1 primary--text font-weight-bold"
                    >{{ classId }}</span>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mx-0">
            <v-col class="py-0">
                <v-card                                    
                    class="px-3"
                    flat
                    light
                    tile                                    
                >
                    <v-row
                        class="pl-3"
                    >
                        
                        <v-col
                            align="center"
                            justify="center"
                            v-for="item in academicPeriod"
                            :key="item.title"
                            class="pa-0"
                            cols="4"                        
                        >
                            <div 
                                class="text-caption blue--text"
                            >{{item.title}}</div>
                            <div 
                                class="text-caption green--text"
                            >{{item.value}}</div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>                                 
        </v-row>

        <v-row class="mr-0">
            <v-col class="d-flex pt-0 pl-2 pr-0">
                <v-card width="30" class="mr-1 d-flex flex-column justify-end" flat>
                    <v-btn 
                        outlined
                        class="mb-3"
                        text
                        height="40"
                        width="20"
                        color="primary"
                        @click="firstRecord"
                        :disabled="!pagination.prev_page" 
                    >
                        <v-icon>mdi-skip-previous</v-icon>
                    </v-btn>

                    <v-btn 
                        outlined
                        text
                        height="40"
                        width="20"
                        color="primary"
                        @click="previousRecord"
                        :disabled="!pagination.prev_page" 
                    >
                        <v-icon large>mdi-menu-left</v-icon>
                    </v-btn>
                </v-card>
                <v-card                
                    :width="width"
                    flat
                >
                    <div>
                        <v-list class="py-0" dense>
                            <v-list-item
                                v-for="item in recordSummary"
                                :key="item.title"
                                class="pr-0"
                            >
                                <v-list-item-content class="pt-0 pb-0">
                                    <v-list-item-title 
                                        v-text="item.title"
                                        class="text-caption"
                                    ></v-list-item-title>
                                </v-list-item-content>
                                <v-card                               
                                    width="80"                                                                                                       
                                    flat
                                    class="pb-1"
                                >
                                    <v-text-field
                                        v-if="!item.select"
                                        :disabled = "item.disabled"
                                        :filled = "item.disabled" 
                                        @blur="updateRecord"
                                        dense
                                        outlined
                                        hide-details
                                        v-model="studentRecord[item.model]"
                                        height="20"
                                        class="text-caption pa-0"
                                    ></v-text-field>

                                    <v-select
                                        v-else-if="item.model == 'times_late'"
                                        :disabled = "item.disabled"
                                        :filled = "item.disabled"
                                        :items="grades"
                                        item-text="grade"
                                        item-value="grade"                                    
                                        v-model="studentRecord[item.model]"
                                        hide-details                                    
                                        outlined
                                        class="text-caption text-center"
                                        @change="updateRecord"
                                        chips
                                        deletable-chips 
                                    ></v-select>

                                    <v-select
                                        v-else-if="item.select"
                                        :disabled = "item.disabled"
                                        :filled = "item.disabled"
                                        :items="grades2"
                                        item-value="grade"
                                        item-text="grade"                                    
                                        v-model="studentRecord[item.model]"
                                        hide-details                                    
                                        outlined
                                        class="text-caption text-center"
                                        @change="updateRecord"
                                        chips
                                        deletable-chips 
                                    >
                                        <!-- <template v-slot:selection="{item}">
                                            {{ item.id }}
                                        </template> -->

                                        <template
                                            v-slot:item=data
                                        > 
                                            {{ data.item.grade}} {{ data.item.title }}
                                        </template>                                   
                                    </v-select>
                                </v-card> 
                            </v-list-item>
                        </v-list>
                    </div>                                     
                </v-card>
                <v-card width="30" class="mr-1 d-flex flex-column justify-end" flat>
                    <v-btn 
                        outlined
                        class="mb-3 ml-4"
                        text
                        height="40"
                        width="20"
                        color="primary"
                        @click="lastRecord"
                        :disabled="!pagination.next_page" 
                    >
                        <v-icon>mdi-skip-next</v-icon>
                    </v-btn>

                    <v-btn 
                        outlined
                        text
                        height="40"
                        width="20"
                        color="primary"
                        class="ml-4"
                        @click="nextRecord"
                        :disabled="!pagination.next_page"
                    >
                        <v-icon large>mdi-menu-right</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mr-0">
            <v-card                                
                class="mb-2 pl-4"
                flat
                :width="width"
            >                
                <v-btn                        
                    outlined
                    color="primary"                        
                    @click="searchStudent"
                    small
                    class="mr-3"
                >
                    <div 
                        class="d-flex flex-column"
                        style="font-size:0.6rem"
                    >
                        <span>Search</span>
                        <span>Student</span>
                    </div>
                </v-btn>

                <v-btn                
                    color="primary"
                    small
                    @click="nextClass"
                >
                    <div 
                        class="d-flex flex-column"
                        style="font-size:0.6rem"
                    >
                        <span>Next</span>
                        <span>Class</span>
                    </div>
                </v-btn>                
            </v-card>
        </v-row>

        <!-- <v-row
            class="mt-3"
        >
            <v-card
                flat
                :width="width"
                class="d-flex justify-space-between pl-4"
            >
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="30"                
                    color="primary"
                    @click="previousRecord"
                    :disabled="!pagination.prev_page"                
                >
                    <div class="d-flex flex-column"> 
                        <span>PREV</span>
                    </div>                                         
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2"
                    width="15"
                    height="30"
                    outlined
                    disabled                
                    text                
                >
                    <span class="primary--text font-weight-bold">{{pagination.current_page}} of {{ pagination.last_page}}</span>                                                        
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="30"
                    dark
                    color="primary"
                    @click="nextRecord"
                    :disabled="!pagination.next_page"
                >
                    <div class="d-flex flex-column">
                        <span>NEXT</span>
                    </div>                                         
                </v-btn>
            </v-card>    
        </v-row> -->

        <v-overlay
            absolute
            :value="overlay"
            color="secondary"
            opacity="0.6"
        >
            <v-expand-transition>
                <v-card
                    max-width="600"
                    class="mx-auto pa-6"
                    transition="scroll-y-transition"
                    v-show="expand"
                    light
                >
                    <v-card-title
                        class="mb-6"
                    >
                        <v-text-field
                            v-model="search"
                            label="Search for Student Record"
                            append-icon="mdi-magnify"
                            class="mx-4"
                            hide-details
                            single-line
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="table1Records"
                        :search="search"
                        fixed-header
                        height="40vh"
                        :options="options"
                        dense
                    >
                        <template
                            v-slot:[`item.actions`]="{ item }"
                        >
                            <v-btn
                                text
                                color="primary"
                                @click="viewRecord(item)"
                            >
                                View Record
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            outlined
                            @click="cancelSearch"
                        >
                            Cancel Search
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </v-overlay>

    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created(){
        this.initialize();
        //console.log(this.extraCurricularFields(1));
    },
    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getSelectedStudentRecord',            
            academicYear: 'termReports/getAcademicYear',
            currentTerm: 'termReports/getCurrentTerm',
            formTeacherClass: 'termReports/getFormTeacherClass',
            codedComments: 'termReports/getCodedComments',
            table1Records: 'termReports/getTable1Records',
            pagination: 'termReports/getPagination',
            formClasses: 'termReports/getFormClassesList',
            admin: 'auth/getAdmin',
        }),

        studentName () {
            return this.studentRecord.first_name + ' ' + this.studentRecord.last_name;
        },

        classId () {
            return this.studentRecord.class_id;
        },

        recordSummary () {
            return [
                {title: 'Student\'s ID', model: 'student_id', select: false, disabled: true, section: 1},
                {title: 'Possible Attendance', model: 'possible_attendance', select: false, disabled: true, section: 1},
                {title: 'No. of Times Absent', model: 'times_absent', select: false, disabled: false, section: 1},
                {title: 'No. Times Late', model: 'times_late', select: false, disabled: false, section: 1},
                {title: 'Conduct/Discipline', model: 'attitude', select: true, disabled: false, section: 1},
                {title: 'Citizenship', model: 'responsibility', select: true, disabled: false, section: 1},
                {title: 'Communication', model: 'honor', select: true, disabled: false, section: 1},
                {title: 'Appearance', model: 'appearance', select: true, disabled: false, section: 1},
                {title: 'Personal Development', model: 'courtesy', select: true, disabled: false, section: 1},
                {title: 'Extra-Curricular', model: 'extra_curricular', select: true, disabled: false, section: 1},
            ]
        },

        termName () {
            let termName = '';
            if(this.currentTerm == 1) termName =  '1';
            if(this.currentTerm == 2) termName = '2';
            if(this.currentTerm == 3) termName = '3';
            return termName
        },             
        
        
    },
    watch: {
        studentRecord: {
            handler () {
                this.setDisabledFields();               
            }
        },
    },
    data: () => ({
        academicPeriod: [
            { title: 'Year', value: '' },
            { title: 'Term', value: '' },
            { title: 'Test', value: 'End of Term' },
        ],
        grades: [
            {grade: 'A', scale: '80 - 100'},
            {grade: 'B', scale: '70 - 79'},
            {grade: 'C', scale: '60 - 69'},
            {grade: 'D', scale: '55 - 59'},
            {grade: 'E', scale: '50 - 54'},
            {grade: 'U', scale: '0 - 49'},
                     
        ],
        grades2: [
            {grade: 'A', title: 'Excellent'},
            {grade: 'B', title: 'Very Good'},
            {grade: 'C', title: 'Good'},
            {grade: 'D', title: 'Satisfactory'},
            {grade: 'E', title: 'Poor'},           
        ],
        
        headers: [
            { text: 'Student ID', align: 'center', value: 'student_id', width: '75' },
            { text: 'First Name', align: 'left', value: 'first_name', width: '100' },
            { text: 'Last Name', align: 'left', value: 'last_name', width: '100' },
            { text: '', align: 'center', value: 'actions', sortable: false, width: '150' },
        ],
        options: {
            itemsPerPage: -1,
        }, 
       
        disabled: false,        
        expand: false,
        overlay: false,
        search: '',
        width: 250,       
    }),
    methods: {
        ...mapActions({ 
            postTable1Record: 'termReports/postTable1Record',
            getTable2Records: 'termReports/getTable2Records', 
            setErrorResponse: 'termReports/setErrorResponse',            
        }),        
        ...mapMutations({         
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setOverlay: 'termReports/setEditViewTermReportsOverlay',
            setPagination: 'termReports/setPagination',           
            setLoading: 'termReports/setEditViewTermReportsLoading',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects', 
            setExpand: 'termReports/setEditViewTermReportsExpand',
        }),
        setRecordSummary(){
            //
        },
        async updateRecord(){
            console.log('updating...');
            console.log(this.studentRecord);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);           
            
            try{
                let response = await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                console.log(response);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },

        initialize(){            
            this.academicPeriod[0].value = this.academicYear;
            this.academicPeriod[1].value = this.termName;            
            //console.log(this.codedComments);            
        },

        searchStudent(){
            this.overlay = true;
            setTimeout(() => {
                this.expand = true;
            })
        },

        viewRecord(item){
            //console.log(item);
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
            let recordIndex = this.table1Records.indexOf(item);
            this.recordNavigation(++recordIndex);
        },

        cancelSearch(){
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
        },

        async recordNavigation(pagination){            
            this.setOverlay(true);
            this.setLoading(true);
            
            this.makePagination(pagination);
            this.setSelectedStudentRecord(this.table1Records[--pagination]);
            try {
                const { data } = await this.getTable2Records();                             
                this.setTable2Records(data.table2_records);
                this.setStudentSubjects(data.student_subjects);                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }            
            this.setOverlay(false);
            this.setLoading(false);            
        },

        makePagination(data){
            let prev_page = data;
            let current_page = data;
            let next_page = (current_page === this.pagination.last_page) ? null : ++data;
            prev_page = (current_page === 1) ? null : --prev_page;
            
            let pagination = {
                current_page: current_page,
                last_page: this.pagination.last_page,
                next_page: next_page,
                prev_page: prev_page,
            };
            this.setPagination(pagination);
        },       

        async previousRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.prev_page);
            console.log('previous');
        },

        firstRecord () {
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(1);
            console.log('first');
        },

        nextRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.next_page);
            console.log('next');
        },

        lastRecord () {
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.last_page);
            console.log('last');
        },
        
        nextClass () {
            this.setOverlay(true);
            this.setExpand(true);
        },

        setDisabledFields () {
            this.disabled = false;
            console.log(`form teacher class: ${this.formTeacherClass}`);
            this.recordSummary.forEach(element => {
                if(                           
                    element.model != 'student_id' &&
                    element.model != 'possible_attendance'
                )
                {                            
                    element.disabled = false;
                }
                else{                            
                    element.disabled = true;
                }
            })
            
            if(
                this.formTeacherClass != this.studentRecord.class_id
                && !this.admin
            )
            {                
                this.disabled = true;                    
                this.recordSummary.forEach(element => {
                    element.disabled = true;
                })
                
            }
                
        }
        
    },

   
}
</script>

<style scoped>
    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    ::v-deep .v-list--dense .v-list-item{
        min-height: 20px;
    }

    .v-btn__content{
        font-size: 0.9em;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 0;        
    }

    ::v-deep .v-select__selections{
        height: 15px;
        /*padding: 0 !important;*/
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        flex-wrap: nowrap;
        display: flex;
        justify-content: center;
    }

    ::v-deep .v-select__selections input{
        display: none;
    }

    ::v-deep .v-select__selection--comma{
        margin: 0 4px 4px 0;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-chip.v-size--default{
        height: 15px;
        font-size: 11px;
    }    

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
            min-height: 8px;
        }

    ::v-deep .v-select.v-select--chips .v-select__selections{
        min-height: 8px;
    }

    ::v-deep .v-select__slot .v-input__append-inner{
        padding-left: 0;
    }

    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 2px;
    }

    ::v-deep .v-btn:not(.v-btn--round).v-size--default{
        min-width: 0px;
    }
</style>